export const accountRoutes = [
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '@/modules/Account/views/AccountView.vue'),
    meta: {
      title: 'account.views.accountView.title',
      headline: 'account.headline',
      auth: true,
    },
  },
  {
    path: '/account/contact-data',
    name: 'account-contact-data',
    component: () => import(/* webpackChunkName: "account-contact-data" */ '@/modules/Account/views/ContactData.vue'),
    meta: {
      title: 'account.views.contactData.title',
      headline: 'account.headline',
      auth: true,
    },
  },
  {
    path: '/account/change-password',
    name: 'account-change-password',
    component: () =>
      import(/* webpackChunkName: "account-change-password" */ '@/modules/Account/views/ChangePassword.vue'),
    meta: {
      title: 'account.views.changePassword.title',
      headline: 'account.headline',
      auth: true,
    },
  },
  {
    path: '/account/log-messages',
    name: 'account-log-messages',
    component: () => import(/* webpackChunkName: "account-log-messages" */ '@/modules/Account/views/LogMessages.vue'),
    meta: {
      title: 'account.views.logMessages.title',
      headline: 'account.headline',
      auth: true,
    },
  },
  {
    path: '/account/daily-report-mail',
    name: 'account-daily-report-mail',
    component: () =>
      import(/* webpackChunkName: "account-daily-report-mail" */ '@/modules/Account/views/DailyReportMail.vue'),
    meta: {
      title: 'account.views.dailyReportMail.title',
      headline: 'account.headline',
      auth: true,
    },
  },
];
