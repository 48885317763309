export const carTranslations = {
  de: {
    car: {
      headline: 'sunbrain.car',
      views: {
        carView: {
          title: 'sunbrain.car',
          status: 'Status',
          alert: {
            offline: {
              title: 'Sunbrain ist offline.',
              message:
                'Die aktuellen Daten können derzeit nicht angezeigt werden.<br />Bitte stellen Sie sicher, dass das Gerät ordnungsgemäß mit dem Internet verbunden ist.',
            },
          },
          car: 'Auto',
          carStatus: {
            0: 'Fehler',
            1: 'n/a',
            2: 'Nicht verbunden',
            3: 'Nicht verbunden',
            4: 'Gestoppt',
            5: 'Gestoppt',
            6: 'Ladebereit',
            7: 'Lädt',
            offline: 'Offline',
          },
          wallbox: 'sunbrain.car',
          wallboxStatus: {
            0: 'Fehler',
            1: 'n/a',
            2: 'Gestoppt',
            3: 'Ladebereit',
            4: 'Gestoppt',
            5: 'Ladebereit',
            6: 'Gestoppt',
            7: 'Lädt',
            offline: 'Offline',
          },
          chargingPrograms: 'sunbrain.car Ladeprogramme',
          chargingProgramsDescription:
            'Ladeprogramme der Wallbox machen das Laden einfach: Wähle aus verschiedenen Modi, um deine Fahrten umweltfreundlich, schnell oder kosteneffizient zu gestalten.',
        },
        advancedMode: {
          title: 'sunbrain.car',
          pleaseSelect: 'Wählen Sie die Ladeenergie und Ladedauer aus.',
          yourSettings: 'Deine individuellen Einstellungen',
          alert: {
            invalidParams: {
              title: 'Ungültige Einstellung.',
              message: 'Die angegebene Ladedauer ist für eine vollständige Aufladung nicht ausreichend.',
            },
          },
          activate: 'Aktivieren',
          cancel: 'Abbrechen',
        },
      },
      components: {
        carChartBox: {
          stop: 'Stopp',
          noData: 'Keine Daten',
        },
        carStatus: {
          statusUnknown: 'Status unbekannt',
          connectCar: 'Fahrzeug bitte anschließen',
          carConnected: 'Fahrzeug angeschlossen',
          activated: 'aktiviert',
          chargingProgramInfo: 'Ladeprogramme Infos',
          infos: 'Infos',
          toChargingPrograms: 'zu den Ladeprogrammen',
        },
        chargingEnergyForm: {
          energy: 'Ladeenergie',
          kwh: 'kWh',
          setup: 'Einstellen:',
          tenSteps: '10er Schritte',
          oneSteps: '1er Schritte',
        },
        chargingTimeForm: {
          chargingTime: 'Ladedauer',
          chargingEndTime: 'Zielzeit',
          hours: 'Stunden',
          clock: 'Uhr',
          setup: 'Einstellen:',
          configure: 'Mit {icons} regulieren.',
        },
      },
      constants: {
        chargingPrograms: {
          ecoMode: {
            title: 'Eco-Modus',
            description: 'Mein Fahrzeug soll schonend mit minimalem Strom geladen werden.',
          },
          fastMode: {
            title: 'Schnell-Modus',
            description: 'Mein Fahrzeug soll schnellstmöglich geladen werden.',
          },
          saveMode: {
            title: 'Spar-Modus',
            description:
              'Mein Fahrzeug soll nur mit Solarstrom geladen werden. Wenn keine Sonne scheint, wird auch nicht geladen. Ich möchte maximal sparen und ökologisch fahren.',
          },
          appointmentMode: {
            title: 'Termin-Modus',
            description:
              'Mein Fahrzeug soll gleichmäßig und innerhalb eines begrenzten Zeitraums bis zu einer definierten Grenze aufgeladen werden.',
          },
          nightMode: {
            title: 'Nacht-Modus',
            description:
              'Mein Fahrzeug wird über Nacht bis zu einer definierten Uhrzeit um eine definierte Ladeenergie aufgeladen. Soweit vorhanden, werden Überschuss aus Sonnenenergie und die Hausbatterie genutzt, die gewünschte Ladung jedoch mit Netzstrom garantiert.',
          },
          compatibilityMode: {
            title: 'Kompatibilität',
            description:
              'Mein Fahrzeug unterstützt kein pausierendes Laden. Es wird über Nacht kontinuierlich bis zu einer definierten Uhrzeit um eine definierte Ladeenergie aufgeladen. Soweit vorhanden, werden Überschuss aus Sonnenenergie und die Hausbatterie genutzt, die gewünschte Ladung jedoch mit Netzstrom garantiert.',
          },
        },
      },
    },
  },
};
