import { LAYOUT_NAMESPACE } from '@/modules/Layout/stores';

export const ACCOUNT_NAMESPACE = 'ACCOUNT_NAMESPACE';

export const configureAccountStore = (Vue) => {
  return {
    namespaced: true,
    state: {
      logMessages: [],
      dailyReportSubscription: null,
    },
    mutations: {
      setLogMessages(state, payload) {
        state.logMessages = payload;
      },
      setDailyReportSubscription(state, payload) {
        state.dailyReportSubscription = payload;
      },
    },
    actions: {
      async updatePassword(_, { plainPasswordCurrent, plainPassword }) {
        try {
          await Vue.prototype.$http.post('/command/user-management/current-user/update-password', {
            plainPasswordCurrent,
            plainPassword,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async updateUser(_, { userId, firstName, lastName, updateEmailReplacement }) {
        try {
          await Vue.prototype.$http.post('/command/user-management/user/update', {
            userId,
            firstName,
            lastName,
            updateEmailReplacement,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async fetchLogMessages({ rootState, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const response = await Vue.prototype.$http.get('/device-management/sunbrain/log-messages?', {
          params: {
            sunbrainId,
          },
        });
        if (sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId) {
          commit('setLogMessages', response.data);
        }
      },
      async readAllLogMessages({ rootState }) {
        try {
          const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
          await Vue.prototype.$http.post('/device-management/sunbrain/log-messages/read-all', {
            sunbrainId,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
      async fetchDailyReportSubscription({ commit }) {
        const response = await Vue.prototype.$http.get(`/user-management/user/settings`);
        commit('setDailyReportSubscription', response.data.dailyReportSubscription);
      },
      async updateDailyReportSubscription(_, dailyReportSubscription) {
        try {
          await Vue.prototype.$http.post('command/user-management/user/update-daily-report-subscription', {
            dailyReportSubscription,
          });
          return true;
        } catch (_) {
          return false;
        }
      },
    },
    getters: {},
  };
};
