import VueI18n from 'vue-i18n';
import { loginTranslations } from '@/modules/Login/translations';
import { legalTranslations } from '@/modules/Legal/translations';
import { accountTranslations } from '@/modules/Account/translations';
import { dashboardTranslations } from '@/modules/Dashboard/translations';
import { analysisTranslations } from '@/modules/Analysis/translations';
import { carTranslations } from '@/modules/Car/translations';
import { settingsTranslations } from '@/modules/Settings/translations';
import { layoutTranslations } from '@/modules/Layout/translations';
import { contactTranslations } from '@/modules/Contact/translations';

export const configureVueI18n = (Vue) => {
  Vue.use(VueI18n);

  const i18n = new VueI18n({
    locale: 'de',
    messages: {
      de: {
        ...layoutTranslations.de,
        ...loginTranslations.de,
        ...legalTranslations.de,
        ...accountTranslations.de,
        ...dashboardTranslations.de,
        ...analysisTranslations.de,
        ...carTranslations.de,
        ...settingsTranslations.de,
        ...contactTranslations.de,
      },
    },
  });

  Vue.prototype.$tf = (key, fallback, translateFallback = true) =>
    i18n.te(key) ? i18n.t(key) : translateFallback ? i18n.t(fallback) : fallback;

  return i18n;
};
