import Vuex from 'vuex';
import { LAYOUT_NAMESPACE, configureLayoutStore } from '@/modules/Layout/stores';
import { LOGIN_NAMESPACE, configureLoginStore } from '@/modules/Login/stores';
import { ACCOUNT_NAMESPACE, configureAccountStore } from '@/modules/Account/stores';
import { DASHBOARD_NAMESPACE, configureDashboardStore } from '@/modules/Dashboard/stores';
import { ANALYSIS_NAMESPACE, configureAnalysisStore } from '@/modules/Analysis/stores';
import { CAR_NAMESPACE, configureCarStore } from '@/modules/Car/stores';
import { SETTINGS_NAMESPACE, configureSettingsStore } from '@/modules/Settings/stores';
import { CONTACT_NAMESPACE, configureContactStore } from '@/modules/Contact/stores';

export const configureVuex = (Vue) => {
  Vue.use(Vuex);

  return new Vuex.Store({
    modules: {
      [LAYOUT_NAMESPACE]: configureLayoutStore(Vue),
      [LOGIN_NAMESPACE]: configureLoginStore(Vue),
      [ACCOUNT_NAMESPACE]: configureAccountStore(Vue),
      [DASHBOARD_NAMESPACE]: configureDashboardStore(Vue),
      [ANALYSIS_NAMESPACE]: configureAnalysisStore(Vue),
      [CAR_NAMESPACE]: configureCarStore(Vue),
      [SETTINGS_NAMESPACE]: configureSettingsStore(Vue),
      [CONTACT_NAMESPACE]: configureContactStore(Vue),
    },
  });
};
