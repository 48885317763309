import VueAxios from 'vue-axios';
import axios from 'axios';

export const configureAxios = (Vue) => {
  Vue.use(VueAxios, axios);

  axios.defaults.baseURL = process.env.VUE_APP_CORE_API_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';

  return axios;
};
