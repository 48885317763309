export const settingsTranslations = {
  de: {
    settings: {
      headline: 'Einstellungen',
      views: {
        settingsView: {
          title: 'Sunbrain',
          lastActivity: 'Letzte aktive Kommunikation',
          clock: 'Uhr',
        },
        relaisSettings: {
          title: 'Anschlusseinstellungen',
          titleWithBreak: 'Anschluss&shy;einstellungen',
          sunbrain: 'Sunbrain',
          alert: {
            save: {
              success:
                '<div class="greenSecondary--text">Übertragung abgeschlossen.</div>Alle Einstellungen erfolgreich gespeichert.',
              error: '<div class="red--text">Übertragung unvollständig.</div>Bitte versuchen Sie es erneut.',
            },
          },
        },
      },
      components: {
        deviceMetaData: {
          serial: 'Seriennummer',
          hardwareversion: 'Hardwareversion',
          softwareversion: 'Softwareversion',
          lastActivity: 'Letzte aktive Kommunikation',
          clock: 'Uhr',
        },
        settingsForm: {
          offline:
            'Die Einstellungen können nur angepasst werden, wenn der Sunbrain über eine aktive Internetverbindung verfügt.',
          relais: 'Anschlüsse',
          alert: {
            warning:
              '<div class="red--text">Achtung!</div> Die nachfolgenden Felder beeinflussen die Funktionsweise Ihres Sunbrains. Ändern Sie diese nur, wenn Sie wirklich sicher sind, was Sie tun.',
          },
          advancedMode: 'Expertenansicht',
          dialog: {
            title: 'Warnung',
            text: 'Durch das Anpassen von Experteneinstellungen kann die <b>Funktionsweise Ihres Sunbrains beeinträchtigt</b> werden. <br /><br />Bei unsachgemäßer Nutzung wird <b>keine Haftung</b> übernommen.',
            submit: 'Verstanden',
            cancel: 'Abbrechen',
          },
        },
        settingsSaveDialog: {
          sunbrain: 'Sunbrain',
          yourChanges: 'Vorgenommene Einstellungen',
          ok: 'OK',
        },
      },
      constants: {
        relais: 'Anschlüsse',
        a1: 'A1',
        a2: 'A2',
        a3: 'A3',
        a4: 'A4',
        a5: 'A5',
        a6: 'A6',
        a7: 'A7',
        a8: 'A8',
        displayName: {
          label: 'Anzeigename',
        },
        relaisMode: {
          label: 'Schaltmodus',
          on: 'Ein',
          off: 'Aus',
          auto: 'Auto',
        },
        relaisSwitchingThreshold: {
          label: 'Schaltschwelle',
          unit: 'Watt',
        },
        relaisSwitchingSource: {
          label: 'Schaltquelle',
          disabled: 'Deaktiviert',
          inverter: 'WR Leistung',
          s0a: 'S0-A Leistung',
          s0b: 'S0-B Leistung',
          s0c: 'S0-C Leistung',
          s0d: 'S0-D Leistung',
          s0e: 'S0-E Leistung',
          s0f: 'S0-F Leistung',
          s0g: 'S0-G Leistung',
          s0h: 'S0-H Leistung',
        },
        relaisPowerOnDelay: {
          label: 'Einschaltverzögerung',
          unit: 'Minute | Minuten',
        },
        relaisPowerOffDelay: {
          label: 'Ausschaltverzögerung',
          unit: 'Minute | Minuten',
        },
        relaisTemperature: {
          label: 'Temperatur',
          unit: 'Grad Celsius',
        },
        relaisHysteresis: {
          label: 'Hysterese',
          unit: 'Grad Celsius',
        },
        relaisSensor: {
          label: 'T-Sensor',
          off: 'Aus',
          t1: 'T1',
          t2: 'T2',
        },
        relaisEmergencyMode: {
          label: 'Notfunktion',
          on: 'Ein',
          off: 'Aus',
          auto: 'Auto',
        },
        relaisBatteryStateOfCharge: {
          label: 'Batterievorrang',
          unit: 'Prozent',
        },
      },
      validation: {
        isEmpty: 'Dieses Feld ist erforderlich.',
        isNumeric: 'Dieses Feld erfordert eine Zahl als Wert.',
        isInRange0to65000: 'Dieses Feld erfordert einen Wert zwischen 0 und 65000.',
        isInRange0to90: 'Dieses Feld erfordert einen Wert zwischen 0 und 90.',
        isInRange0to1000: 'Dieses Feld erfordert einen Wert zwischen 0 und 1000.',
        isInRange0to100: 'Dieses Feld erfordert einen Wert zwischen 0 und 100.',
        isInRange10to65000: 'Dieses Feld erfordert einen Wert zwischen 10 und 65000.',
        isInRangeMinus100to100: 'Dieses Feld erfordert einen Wert zwischen -100 und 100.',
        isInRange0to900: 'Dieses Feld erfordert einen Wert zwischen 0 und 900.',
      },
    },
  },
};
