export const loginTranslations = {
  de: {
    login: {
      views: {
        loginView: {
          title: 'Anmeldung',
          alert: {
            genericError: 'Beim Login ist ein unerwarteter Fehler aufgetreten.',
            invalidCredentials: 'E-Mail-Adresse oder Passwort falsch.',
            forgottenPasswordReplaced: 'Passwort erfolgreich zurückgesetzt.',
            accountActivated: 'Account erfolgreich aktiviert.',
            emailVerified: 'E-Mail-Adresse erfolgreich bestätigt. Bitte loggen Sie sich neu ein.',
            noViewerRole: 'Login im Kundenbereich nicht möglich. Bitte nutzen Sie den Login im Adminbereich.',
          },
          forgotPassword: 'Passwort vergessen',
        },
        forgotPassword: {
          title: 'Passwort vergessen',
          pleaseEnter: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
          alert: {
            genericError: 'Beim Zurücksetzen des Passworts ist ein unerwarteter Fehler aufgetreten.',
            mailSent: 'Bitte prüfen Sie nun Ihr Postfach und klicken Sie auf den Link in der Bestätigungsmail.',
          },
          back: 'Zurück zum Login',
        },
        replaceForgottenPassword: {
          title: 'Passwort ändern',
          pleaseEnter:
            'Bitte vergeben Sie nun ein neues Passwort für Ihren Account und bestätigen Sie es durch erneute Eingabe.',
          alert: {
            genericError: 'Beim Speichern des Passworts ist ein unerwarteter Fehler aufgetreten.',
            invalidLink: 'Der aufgerufene Link ist ungültig.',
          },
        },
        activateAccount: {
          title: 'Aktivierung',
          pleaseEnter:
            'Bitte vergeben Sie ein Passwort und bestätigen Sie es durch erneute Eingabe um Ihren Account zu aktivieren.',
          alert: {
            genericError: 'Beim Aktivieren des Accounts ist ein unerwarteter Fehler aufgetreten.',
            invalidLink: 'Der aufgerufene Link ist ungültig.',
          },
        },
        verifyEmail: {
          title: 'E-Mail-Adresse bestätigen',
          alert: {
            genericError: 'Beim Bestätigen der E-Mail-Adresse ist ein unerwarteter Fehler aufgetreten.',
            invalidLink: 'Der aufgerufene Link ist ungültig.',
          },
        },
        impersonate: {
          title: 'Authentifizierung',
          alert: {
            invalidLink: 'Der aufgerufene Link ist ungültig.',
          },
        },
        deviceSelection: {
          title: 'Sunbrain auswählen',
          cancel: 'Abbrechen',
        },
      },
      components: {
        loginForm: {
          email: 'E-Mail-Adresse',
          password: 'Passwort',
          submit: 'Anmelden',
        },
        forgotPasswordForm: {
          email: 'E-Mail-Adresse',
          submit: 'Bestätigungsmail anfordern',
        },
        replacePasswordForm: {
          password: 'Passwort',
          retypedPassword: 'Passwort wiederholen',
          submit: 'Passwort ändern',
        },
        activateAccountForm: {
          password: 'Passwort',
          retypedPassword: 'Passwort wiederholen',
          theFollowingAreValid: 'Es gelten unsere {agb}.',
          agb: 'Allgemeinen Geschäftsbedingungen',
          infoAboutPrivacy: 'Informationen zum Datenschutz finden Sie in unserer {privacy}.',
          privacy: 'Datenschutzerklärung',
          submit: 'Account aktivieren',
        },
        deviceForm: {
          serial: 'Seriennr.',
          select: 'Wählen',
        },
        deviceInfo: {
          whereCanIFind: 'Wo finde ich die Seriennummer?',
          serial: 'Seriennummer',
          text: 'Die <b>Seriennummer</b> Ihres Sunbrains finden Sie auf dem Display des Geräts. Tippen Sie hierzu das Wolken-Symbol im rechten oberen Bereich an, um eine Übersicht der Geräte-Daten zu öffnen. Die Seriennummer befindet sich unter dem Punkt "Steuerung".',
        },
      },
      validation: {
        isEmpty: 'Dieses Feld ist erforderlich.',
        isNotEmail: 'Dieses Feld enthält keine gültige E-Mail-Adresse.',
        passwordsNotEqual: 'Die beiden Passwörter stimmen nicht überein.',
      },
    },
  },
};
