<template>
  <v-navigation-drawer v-model="drawer" hide-overlay floating touchless fixed>
    <drawer-content
      ref="drawer-content"
      :device-list="deviceList"
      :current-device-id="currentDeviceId"
      :menu-items="menuItems"
      closeable
      @close="drawer = false"
      @deviceSwitch="onDeviceSwitch"
    />
    <template #append>
      <drawer-footer :footer-items="footerItems" @logout="$emit('logout')" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import DrawerContent from '@/modules/Layout/components/AuthLayout/Drawer/DrawerContent.vue';
import DrawerFooter from '@/modules/Layout/components/AuthLayout/Drawer/DrawerFooter.vue';
import { drawerMenuItems, drawerFooterItems } from '@/modules/Layout/constants';

export default {
  components: { DrawerContent, DrawerFooter },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    deviceList: {
      type: Array,
      required: true,
    },
    currentDeviceId: {
      type: String,
      default: null,
    },
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    menuItems() {
      return drawerMenuItems;
    },
    footerItems() {
      return drawerFooterItems;
    },
  },
  watch: {
    $route() {
      this.drawer = false;
    },
  },
  methods: {
    onDeviceSwitch(id) {
      this.$emit('deviceSwitch', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  width: 100svw !important;
  height: 100svh !important;
}
</style>
