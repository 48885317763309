export const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/modules/Dashboard/views/DashboardView.vue'),
    meta: {
      title: 'dashboard.views.dashboardView.title',
      headline: 'dashboard.headline',
      auth: true,
    },
  },
];
