import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { configureVueRouter } from '@/plugins/vue-router';
import { configureVuex } from '@/plugins/vuex';
import { configureVueI18n } from '@/plugins/vue-i18n';
import { configureSentry } from '@/plugins/sentry';
import { configureAxios } from '@/plugins/vue-axios';
import { configureVueAuth } from '@/plugins/vue-auth';
import { configureVueECharts } from '@/plugins/vue-echarts';
import { configureVueFragment } from '@/plugins/vue-fragment';

const router = configureVueRouter(Vue);
const store = configureVuex(Vue);
const i18n = configureVueI18n(Vue);
const axios = configureAxios(Vue);
configureSentry(Vue, router);
configureVueAuth(Vue, router, axios);
configureVueECharts(Vue);
configureVueFragment(Vue);

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['svg-animate'];

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
