export const drawerMenuItems = [
  {
    to: { name: 'dashboard' },
    icon: require('@/modules/Layout/assets/icons/dashboard.svg'),
    title: 'layout.constants.drawerMenuItems.dashboard',
    exact: true,
  },
  {
    to: { name: 'analysis' },
    icon: require('@/modules/Layout/assets/icons/analysis.svg'),
    title: 'layout.constants.drawerMenuItems.analysis',
    exact: false,
    subItems: [
      {
        to: { name: 'analysis-generation-and-consumption' },
        title: 'layout.constants.drawerMenuItems.generationAndConsumption',
        exact: true,
      },
      {
        to: { name: 'analysis-profitability' },
        title: 'layout.constants.drawerMenuItems.profitability',
        exact: true,
      },
      {
        to: { name: 'analysis-ecology' },
        title: 'layout.constants.drawerMenuItems.ecology',
        exact: true,
      },
    ],
  },
  {
    to: { name: 'car' },
    icon: require('@/modules/Layout/assets/icons/car.svg'),
    title: 'layout.constants.drawerMenuItems.car',
    exact: true,
  },
  {
    to: { name: 'settings' },
    icon: require('@/modules/Layout/assets/icons/settings.svg'),
    title: 'layout.constants.drawerMenuItems.settings',
    exact: true,
  },
  {
    to: { name: 'contact' },
    icon: require('@/modules/Layout/assets/icons/contact.svg'),
    title: 'layout.constants.drawerMenuItems.contact',
    exact: true,
  },
];

export const drawerFooterItems = [
  {
    to: { name: 'imprint' },
    title: 'layout.constants.drawerFooterItems.imprint',
    exact: true,
  },
  {
    to: { name: 'privacy' },
    title: 'layout.constants.drawerFooterItems.privacy',
    exact: true,
  },
];
