import { LAYOUT_NAMESPACE } from '@/modules/Layout/stores';
import { endOfDay, endOfMonth, endOfYear, format, startOfDay, startOfMonth, startOfYear } from 'date-fns';

export const ANALYSIS_NAMESPACE = 'ANALYSIS_NAMESPACE';

export const configureAnalysisStore = (Vue) => {
  return {
    namespaced: true,
    state: {
      analysisData: {
        totalGeneration: 0.0,
        totalConsumption: 0.0,
        totalSaved: 0.0,
        moneySaved: 0.0,
        co2Saved: 0.0,
      },
      generationAndConsumptionData: {
        scalingFormData: {
          scaling: 'day',
          date: new Date(),
        },
        timespanData: {
          combined: [],
          generator: [],
          consumer: [],
        },
        pieData: {
          totalGeneration: 0.0,
          totalConsumption: 0.0,
          generatedUsed: 0.0,
          generatedUnused: 0.0,
          consumedOwned: 0.0,
          consumedPayed: 0.0,
        },
      },
      profitability: {
        scalingFormData: {
          scaling: 'day',
          date: new Date(),
        },
        energyBalance: {
          totalGeneration: 0.0,
          totalConsumption: 0.0,
          totalSaved: 0.0,
          moneySaved: 0.0,
          co2Saved: 0.0,
          powerSupply: 0,
        },
      },
      economyEfficiencyConversionFactors: [
        {
          identifier: '',
          description: '',
          unitOfMeasure: '',
          heatingValuePerUnit: '',
          conversionFactor: '',
          pricePerUnit: '',
        },
      ],
      ecology: {
        totalGeneration: 0.0,
        totalConsumption: 0.0,
        totalSaved: 0.0,
        moneySaved: 0.0,
        co2Saved: 0.0,
        powerSupply: 0.0,
        selfSufficiency: 0,
        feed: 0,
      },
      selfSufficiencyConversionFactors: [
        {
          identifier: '',
          description: '',
          conversionFactor: '',
        },
      ],
    },
    mutations: {
      setAnalysisData(state, payload) {
        state.analysisData = payload;
      },
      setGenerationAndConsumptionData(state, payload) {
        state.generationAndConsumptionData = payload;
      },
      setProfitability(state, payload) {
        state.profitability = payload;
      },
      setEconomyEfficiencyConversionFactors(state, payload) {
        state.economyEfficiencyConversionFactors = payload;
      },
      setEcology(state, payload) {
        state.ecology = payload;
      },
      setSelfSufficiencyConversionFactors(state, payload) {
        state.selfSufficiencyConversionFactors = payload;
      },
    },
    actions: {
      async fetchAnalysisData({ rootState, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const response = await Vue.prototype.$http.get('/customer-ui/dashboard/analysis-data', {
          params: {
            sunbrainId,
          },
        });
        if (sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId) {
          commit('setAnalysisData', {
            totalGeneration: response.data.totalGeneration ?? 0.0,
            totalConsumption: response.data.totalConsumption ?? 0.0,
            totalSaved: response.data.totalSaved ?? 0.0,
            moneySaved: response.data.moneySaved ?? 0.0,
            co2Saved: response.data.co2Saved ?? 0.0,
          });
        }
      },
      async fetchGenerationAndConsumptionData({ rootState, state, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const { date, scaling } = state.generationAndConsumptionData.scalingFormData;
        const timespanConfig = {};
        const pieConfig = {};
        switch (scaling) {
          case 'year':
            timespanConfig.params = {
              sunbrainId,
              startDate: format(startOfYear(date), 'yyyy-MM-dd'),
              endDate: format(endOfYear(date), 'yyyy-MM-dd'),
              resolution: 'month',
            };
            pieConfig.params = {
              sunbrainId,
              time: format(date, 'yyyy'),
              resolution: 'year',
            };
            break;
          case 'month':
            timespanConfig.params = {
              sunbrainId,
              startDate: format(startOfMonth(date), 'yyyy-MM-dd'),
              endDate: format(endOfMonth(date), 'yyyy-MM-dd'),
              resolution: 'day',
            };
            pieConfig.params = {
              sunbrainId,
              time: format(date, 'MM-yyyy'),
              resolution: 'month',
            };
            break;
          default:
            timespanConfig.params = {
              sunbrainId,
              startDate: format(startOfDay(date), 'yyyy-MM-dd'),
              endDate: format(endOfDay(date), 'yyyy-MM-dd'),
              resolution: 'minute',
            };
            pieConfig.params = {
              sunbrainId,
              time: format(date, 'yyyy-MM-dd'),
              resolution: 'day',
            };
        }
        const timespanResponse = await Vue.prototype.$http.get('/customer-ui/analysis/timespan-data', timespanConfig);
        const pieResponse = await Vue.prototype.$http.get('/customer-ui/analysis/energy-balance', pieConfig);
        if (
          sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId &&
          date === state.generationAndConsumptionData.scalingFormData.date &&
          scaling === state.generationAndConsumptionData.scalingFormData.scaling
        ) {
          const totalGeneration = parseFloat(pieResponse.data.totalGeneration ?? 0.0);
          const totalConsumption = parseFloat(pieResponse.data.totalConsumption ?? 0.0);
          commit('setGenerationAndConsumptionData', {
            ...state.generationAndConsumptionData,
            timespanData: {
              combined: timespanResponse.data.combined || [],
              generator: timespanResponse.data.generator || [],
              consumer: timespanResponse.data.consumer || [],
            },
            pieData: {
              totalGeneration,
              totalConsumption,
              generatedUsed:
                totalConsumption > totalGeneration
                  ? 100
                  : 0 === totalGeneration
                  ? 0
                  : (100 / totalGeneration) * totalConsumption,
              generatedUnused:
                totalConsumption > totalGeneration
                  ? 0
                  : 0 === totalGeneration
                  ? 0
                  : 100 - (100 / totalGeneration) * totalConsumption,
              consumedOwned:
                totalGeneration > totalConsumption
                  ? 100
                  : 0 === totalConsumption
                  ? 0
                  : (totalGeneration / totalConsumption) * 100,
              consumedPayed:
                totalGeneration > totalConsumption
                  ? 0
                  : 0 === totalConsumption
                  ? 0
                  : 100 - (totalGeneration / totalConsumption) * 100,
            },
          });
        }
      },
      async fetchEnergyBalance({ rootState, state, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const { date, scaling } = state.profitability.scalingFormData;
        const config = {};
        switch (scaling) {
          case 'year':
            config.params = {
              sunbrainId,
              time: format(date, 'yyyy'),
              resolution: 'year',
            };
            break;
          case 'month':
            config.params = {
              sunbrainId,
              time: format(date, 'MM-yyyy'),
              resolution: 'month',
            };
            break;
          default:
            config.params = {
              sunbrainId,
              time: format(date, 'yyyy-MM-dd'),
              resolution: 'day',
            };
        }
        const response = await Vue.prototype.$http.get('/customer-ui/analysis/energy-balance', config);
        if (
          sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId &&
          date === state.profitability.scalingFormData.date &&
          scaling === state.profitability.scalingFormData.scaling
        ) {
          commit('setProfitability', {
            ...state.profitability,
            energyBalance: {
              totalGeneration: response.data.totalGeneration ?? 0.0,
              totalConsumption: response.data.totalConsumption ?? 0.0,
              totalSaved: response.data.totalSaved ?? 0.0,
              moneySaved: response.data.moneySaved ?? 0.0,
              co2Saved: response.data.co2Saved ?? 0.0,
              powerSupply: response.data.powerSupply ?? 0,
            },
          });
        }
      },
      async fetchEconomyEfficiencyConversionFactors({ commit }) {
        const response = await Vue.prototype.$http.get('/customer-ui/conversion-factors/economic-efficiency/list');
        commit(
          'setEconomyEfficiencyConversionFactors',
          response.data.map((factor) => ({
            identifier: factor.identifier,
            description: factor.description,
            unitOfMeasure: factor.unitOfMeasure,
            heatingValuePerUnit: factor.heatingValuePerUnit,
            conversionFactor: factor.conversionFactor,
            pricePerUnit: factor.pricePerUnit,
          }))
        );
      },
      async fetchEcology({ rootState, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const config = {
          params: {
            sunbrainId,
            time: format(new Date(), 'yyyy'),
            resolution: 'year',
          },
        };
        const response = await Vue.prototype.$http.get('/customer-ui/analysis/energy-balance', config);
        if (sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId) {
          const totalGeneration = parseFloat(response.data.totalGeneration ?? 0.0);
          const totalConsumption = parseFloat(response.data.totalConsumption ?? 0.0);
          const powerSupply = parseFloat(response.data.powerSupply ?? 0.0);
          const powerSupplyInPercent = powerSupply > 0 ? (100 * powerSupply) / totalGeneration : 0;
          commit('setEcology', {
            totalGeneration: totalGeneration,
            totalConsumption: totalConsumption,
            totalSaved: response.data.totalSaved ?? 0.0,
            moneySaved: response.data.moneySaved ?? 0.0,
            co2Saved: response.data.co2Saved ?? 0.0,
            powerSupply: powerSupply,
            powerSupplyInPercent: powerSupplyInPercent,
            generation: 100 - powerSupplyInPercent,
            selfSufficiency:
              totalGeneration > totalConsumption
                ? 100
                : 0 === totalConsumption
                ? 100
                : (100 * totalGeneration) / totalConsumption,
            feed: totalGeneration > totalConsumption ? (100 * totalConsumption - powerSupply) / totalGeneration : 0,
          });
        }
      },
      async fetchSelfSufficiencyConversionFactors({ commit }) {
        const response = await Vue.prototype.$http.get('/customer-ui/conversion-factors/self-sufficiency/list');
        commit(
          'setSelfSufficiencyConversionFactors',
          response.data.map((factor) => ({
            identifier: factor.identifier,
            description: factor.description,
            conversionFactor: factor.conversionFactor,
          }))
        );
      },
    },
    getters: {},
  };
};
