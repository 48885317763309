import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export const configureSentry = (Vue, router) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      tracesSampleRate: 0.2,
    });
  }
};
