import VueRouter from 'vue-router';
import { legalRoutes } from '@/modules/Legal/routes';
import { loginRoutes } from '@/modules/Login/routes';
import { accountRoutes } from '@/modules/Account/routes';
import { dashboardRoutes } from '@/modules/Dashboard/routes';
import { analysisRoutes } from '@/modules/Analysis/routes';
import { carRoutes } from '@/modules/Car/routes';
import { settingsRoutes } from '@/modules/Settings/routes';
import { contactRoutes } from '@/modules/Contact/routes';

export const configureVueRouter = (Vue) => {
  Vue.use(VueRouter);

  return new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
      {
        path: '',
        redirect: { name: 'dashboard' },
      },
      ...legalRoutes,
      ...loginRoutes,
      ...accountRoutes,
      ...dashboardRoutes,
      ...analysisRoutes,
      ...carRoutes,
      ...settingsRoutes,
      ...contactRoutes,
      {
        path: '*',
        redirect: { name: 'dashboard' },
      },
    ],
  });
};
