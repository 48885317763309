export const contactTranslations = {
  de: {
    contact: {
      headline: 'Kontakt',
      components: {
        partnerData: {
          phone: 'Telefon',
          email: 'E-Mail',
        },
      },
      views: {
        contactView: {
          title: 'Kontakt',
          pleaseSelect: 'Bitte wählen Sie ein Thema aus, um den passenden Ansprechpartner zu finden',
        },
      },
    },
  },
};
