import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.esm';
import DriverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm';
import DriverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm';

export const configureVueAuth = (Vue, router, axios) => {
  Vue.use(VueAuth, {
    plugins: {
      http: axios,
      router: router,
    },
    drivers: {
      auth: {
        request: function (req, token) {
          const headers = { Authorization: 'Bearer ' + token };

          try {
            const impersonateData = JSON.parse(window.localStorage.getItem('sunbrain-frontend-impersonate-data'));
            if (token === impersonateData.token) {
              headers['X-Switch-User'] = impersonateData.emailAddress;
            }
          } catch (_) {
            window.localStorage.removeItem('sunbrain-frontend-impersonate-data');
          }

          this.drivers.http.setHeaders.call(this, req, headers);
        },
        response: function (res) {
          return res?.data?.token;
        },
      },
      http: DriverHttpAxios,
      router: DriverRouterVueRouter,
    },
    options: {
      tokenDefaultKey: 'sunbrain-frontend-token',
      loginData: {
        url: 'login',
      },
      fetchData: {
        url: 'current-user',
      },
      refreshData: {
        enabled: false,
      },
      parseUserData(data) {
        return data || {};
      },
    },
  });
};
