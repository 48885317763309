export const LAYOUT_NAMESPACE = 'LAYOUT_NAMESPACE';

export const configureLayoutStore = (Vue) => {
  return {
    namespaced: true,
    state: {
      deviceList: [],
      currentDeviceId: null,
      logMessageCount: 0,
    },
    mutations: {
      setDeviceList(state, payload) {
        state.deviceList = payload;
      },
      setCurrentDeviceId(state, payload) {
        state.currentDeviceId = payload;
      },
      setLogMessagesCount(state, payload) {
        state.logMessageCount = payload;
      },
    },
    actions: {
      async fetchDeviceList({ commit }) {
        const response = await Vue.prototype.$http.get('/customer-ui/current-user/sunbrains');
        commit(
          'setDeviceList',
          response.data.map((device) => ({ id: device.sunbrainId, serial: device.serial }))
        );
      },
      async fetchLogMessagesCount({ commit, state }) {
        const sunbrainId = state.currentDeviceId;
        const response = await Vue.prototype.$http.get('/device-management/sunbrain/log-messages-unread-count', {
          params: {
            sunbrainId,
          },
        });
        if (sunbrainId === state.currentDeviceId) {
          commit('setLogMessagesCount', response.data);
        }
      },
    },
    getters: {},
  };
};
