import { LAYOUT_NAMESPACE } from '@/modules/Layout/stores';

export const CAR_NAMESPACE = 'CAR_NAMESPACE';

export const configureCarStore = (Vue) => {
  return {
    namespaced: true,
    state: {
      carData: {
        offline: false,
        lastActivity: null,
        wallBoxChargeState: 0,
        wallBoxPower: 0,
        wallBoxPowerCounterOn: 0,
        wallBoxManufacturer: 0,
        wallBoxChargeProgram: 0,
        wallBoxChargeElectricityLimit: 0,
        wallBoxChargePowerLimit: 0,
        wallBoxPhaseConfig: 0,
        wallBoxPowerChartData: [],
      },
      carDataCollections: {
        wallBoxPower: [],
      },
    },
    mutations: {
      setCarData(state, payload) {
        state.carData = payload;
      },
    },
    actions: {
      async fetchCarData({ rootState, commit, state }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const response = await Vue.prototype.$http.get('/device-management/sunbrain/car-settings/current', {
          params: {
            sunbrainId,
          },
        });
        if (sunbrainId === rootState[LAYOUT_NAMESPACE].currentDeviceId) {
          commit('setCarData', {
            offline: response.data.offline,
            lastActivity: response.data.lastActivity,
            wallBoxChargeState: parseInt(response.data.data?.wallBoxChargeState || 0),
            wallBoxPower: parseFloat(response.data.data?.wallBoxPower || 0),
            wallBoxPowerCounterOn: parseFloat(response.data.data?.wallBoxPowerCounterOn || 0),
            wallBoxManufacturer: parseInt(response.data.data?.wallBoxManufacturer || 0),
            wallBoxChargeProgram: parseInt(response.data.data?.wallBoxChargeProgram || 0),
            wallBoxChargeElectricityLimit: parseInt(response.data.data?.wallBoxChargeElectricityLimit || 0),
            wallBoxChargePowerLimit: parseInt(response.data.data?.wallBoxChargePowerLimit || 0),
            wallBoxPhaseConfig: parseInt(response.data.data?.wallBoxPhaseConfig || 0),
            wallBoxPowerChartData: [
              ...state.carData.wallBoxPowerChartData.slice(-99),
              [new Date(), parseFloat(response.data.data?.wallBoxPower || 0)],
            ],
          });
        }
      },
      async setCarSettings(
        { rootState },
        { wallBoxChargeProgram, wallBoxChargeElectricityLimit, wallBoxChargePowerLimit }
      ) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        await Vue.prototype.$http.post('/device-management/sunbrain/car-settings/update', {
          sunbrainId,
          wallBoxChargeProgram: String(wallBoxChargeProgram),
          wallBoxChargeElectricityLimit: String(wallBoxChargeElectricityLimit),
          wallBoxChargePowerLimit: String(wallBoxChargePowerLimit),
        });
      },
    },
    getters: {
      minWallBoxPowerLimit: (state) => {
        switch (state.carData.wallBoxPhaseConfig) {
          case 5:
            // Phase 1+3 dynamik
            return 1.4;
          case 4:
            // Phase 1-3 dynamik
            return 1.4;
          case 3:
            // Phase 1-2 dynamik
            return 1.4;
          case 2:
            // Phase 3 fest
            return 4.2;
          case 1:
            // Phase 2 fest
            return 2.8;
          default:
            // Phase 1 fest
            return 1.4;
        }
      },
      maxWallBoxPowerLimit: (state) => {
        switch (state.carData.wallBoxPhaseConfig) {
          case 5:
            // Phase 1+3 dynamik
            return 11.1;
          case 4:
            // Phase 1-3 dynamik
            return 11.1;
          case 3:
            // Phase 1-2 dynamik
            return 7.2;
          case 2:
            // Phase 3 fest
            return 11.1;
          case 1:
            // Phase 2 fest
            return 7.2;
          default:
            // Phase 1 fest
            return 3.7;
        }
      },
    },
  };
};
