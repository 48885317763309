<template>
  <div :class="{ minimized }" class="drawer-content">
    <div v-if="closeable" class="ma-3 mb-n12">
      <v-btn icon @click="$emit('close')">
        <v-icon color="secondary">fa-light fa-times</v-icon>
      </v-btn>
    </div>
    <div class="logo">
      <inline-svg :src="require('@/modules/Layout/assets/symbol.svg')" class="mini" />
      <inline-svg :src="require('@/modules/Layout/assets/logo.svg')" />
    </div>
    <div class="content">
      <v-list class="device-switch">
        <v-list-item>
          <v-list-item-icon />
          <v-list-item-title>
            <v-select v-model="deviceSwitchModel" :items="deviceSwitchItems" color="secondary">
              <template #append>
                <v-icon color="secondary" class="mt-1" small>fa-light fa-chevron-down</v-icon>
              </template>
            </v-select>
          </v-list-item-title>
          <v-list-item-icon />
        </v-list-item>
      </v-list>
      <v-list class="navigation" nav>
        <template v-for="({ to, exact, icon, title, subItems }, key) in menuItems">
          <v-list-item
            :key="key"
            ref="glider-position"
            :to="to"
            :exact="exact"
            :ripple="false"
            :class="{ 'v-list-item--sublink-active': isSublinkActive(to) }"
          >
            <v-list-item-icon>
              <inline-svg :src="icon" />
            </v-list-item-icon>
            <v-list-item-title>{{ $t(title) }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon color="secondary" small>fa-light fa-arrow-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-expand-transition v-if="subItems" :key="`${key}-submenu`">
            <div v-if="!minimized" class="submenu">
              <template v-for="subItem in subItems">
                <v-list-item :key="subItem.key" :to="subItem.to" :exact="subItem.exact" :ripple="false">
                  <v-list-item-icon />
                  <v-list-item-title>{{ $t(subItem.title) }}</v-list-item-title>
                </v-list-item>
              </template>
            </div>
          </v-expand-transition>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
export default {
  components: {
    InlineSvg,
  },
  props: {
    deviceList: {
      type: Array,
      required: true,
    },
    currentDeviceId: {
      type: String,
      default: null,
    },
    menuItems: {
      type: Array,
      required: true,
    },
    minimized: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    deviceSwitchModel: {
      get() {
        return this.currentDeviceId;
      },
      set(id) {
        this.$emit('deviceSwitch', id);
      },
    },
    deviceSwitchItems() {
      return this.deviceList.map((device) => ({
        text: `Sunbrain SN${String(device.serial).padStart(3, '0')}`,
        value: device.id,
      }));
    },
  },
  methods: {
    isSublinkActive(to) {
      return this.$route?.name !== to.name && this.$route?.name?.startsWith(to.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-content {
  .logo {
    width: 40px;
    height: 50px;
    margin: 50px auto 12px auto;
    overflow: hidden;
    position: relative;
    transition-duration: 0.2s;
    transition-property: width, height;

    svg {
      position: absolute;
      top: 0;
      transition-duration: 0.2s;
      transition-property: opacity;
    }

    svg.mini {
      width: 40px;
      opacity: 1;
    }

    svg:not(.mini) {
      width: 180px;
      opacity: 0;
    }
  }

  .content {
    max-width: 450px;
    margin: auto;

    .device-switch {
      height: 80px;

      .v-select {
        ::v-deep(.v-select__selections) {
          flex-wrap: nowrap;
        }
      }
    }

    .navigation {
      .v-list-item {
        transition-duration: 0.2s;
        transition-property: transform;

        .v-list-item__icon {
          color: white;
          transition-duration: 0.2s;
          transition-property: color, transform;

          svg {
            width: 24px;
          }
        }

        &:hover {
          .v-list-item__icon:nth-child(3) {
            transform: scale(1.2);
          }
          .v-list-item__title {
            color: var(--v-secondary-base);
          }
        }

        &::before {
          width: 40px;
          height: 40px;
          margin: -20px 0 0 -20px;
          border-radius: 20px;
          background-color: white;
          opacity: 0;
          top: 50%;
          left: 20px;
          z-index: -1;
          transition-duration: 0.2s;
          transition-property: transform, opacity;
        }

        &--active,
        &--sublink-active {
          transform: translateX(10px);

          .v-list-item__icon {
            color: black;
          }

          .v-list-item__title {
            color: var(--v-secondary-base);
          }

          &::before {
            opacity: 1;
            transform: scale(1.3);
          }
        }

        &--sublink-active {
          .v-list-item__title {
            color: var(--v-primary-base);
          }
        }
      }

      .submenu {
        position: relative;
        top: -16px;

        .v-list-item {
          color: var(--v-grey-base);
          min-height: 32px;
          margin: 0;

          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  &:not(.minimized) {
    .logo {
      width: 180px;

      svg.mini {
        opacity: 0;
      }

      svg:not(.mini) {
        opacity: 1;
      }
    }

    .content {
      .navigation {
        .v-list-item {
          .v-list-item__icon {
            color: black;
          }

          &--active,
          &--sublink-active {
            transform: translateX(0);

            .v-list-item__icon {
              svg {
                transform: scale(1);
              }
            }

            &::before {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}
</style>
