export const accountTranslations = {
  de: {
    account: {
      headline: 'Profil',
      views: {
        accountView: {
          title: 'Mein Profil',
        },
        contactData: {
          title: 'Kontaktdaten',
          alert: {
            genericError: 'Beim Ändern der Kontaktdaten ist ein Fehler aufgetreten.',
            contactDataChanged: 'Kontaktdaten erfolgreich geändert.',
            mailSent:
              'Zum Aktivieren der neuen E-Mail-Adresse prüfen Sie bitte Ihr Postfach und klicken Sie auf den Link in der Bestätigungsmail.',
          },
        },
        changePassword: {
          title: 'Passwort ändern',
          alert: {
            genericError: 'Beim Ändern des Passworts ist ein Fehler aufgetreten.',
            passwordChanged: 'Passwort erfolgreich geändert.',
          },
        },
        logMessages: {
          title: 'Systemnachrichten',
          types: {
            error: 'Fehler',
            warning: 'Warnung',
            success: 'Erfolg',
            info: 'Info',
          },
          unsubscribeDailyReport: 'Tagesbericht abbestellen',
        },
        dailyReportMail: {
          title: 'Tagesberichte',
          dailyReportMailForm: {
            title: 'Tagesberichte Per Mail',
            text: 'Mit Hilfe der Tagesberichte erhalten Sie einen Überblick über die Tagesertrags-Werte unserer PV-Anlage im Vergleich zum Vortag. Dies ermöglicht Ihnen, die Leistungsentwicklung der Anlage im Zeitverlauf zu verfolgen und mögliche Abweichungen oder Trends zu erkennen.',
            active: 'aktiviert',
            inactive: 'deaktiviert',
            submit: 'Speichern',
          },
          alert: {
            genericError: 'Beim Ändern der Einstellungen ist ein Fehler aufgetreten.',
            dailyReportSubscriptionChanged: 'Einstellung erfolgreich geändert.',
          },
        },
      },
      components: {
        accountNavigation: {
          contactData: 'Kontaktdaten',
          changePassword: 'Passwort ändern',
          logMessages: 'Systemnachrichten',
          dailyReportMail: 'Tagesbericht per Mail',
        },
        contactDataForm: {
          firstName: 'Vorname',
          lastName: 'Nachname',
          email: 'E-Mail-Adresse',
          change: 'Daten anpassen',
          submit: 'Speichern',
        },
        changePasswordForm: {
          password: 'Aktuelles Passwort',
          newPassword: 'Neues Passwort',
          retypedPassword: 'Neues Passwort wiederholen',
          submit: 'Passwort ändern',
        },
      },
      validation: {
        isEmpty: 'Dieses Feld ist erforderlich.',
        isNotEmail: 'Dieses Feld enthält keine gültige E-Mail-Adresse.',
        passwordsNotEqual: 'Die beiden Passwörter stimmen nicht überein.',
      },
    },
  },
};
