<template>
  <v-snackbar
    min-width="0"
    max-width="90vw"
    v-bind="$attrs"
    :value="value"
    :timeout="-1"
    transition="scroll-y-reverse-transition"
    class="app-install-prompt"
    light
  >
    <div class="primary--text pa-2">
      <div class="secondary--text text-h4">
        {{ $t('layout.components.appInstallPrompt.title') }}
      </div>
      <div class="pt-4">
        <slot />
      </div>
      <div class="text-right pt-4">
        <v-btn color="primary" plain class="mr-n4" @click="cancel">
          {{ $t('layout.components.appInstallPrompt.close') }}
        </v-btn>
        <v-btn v-if="showConfirmButton" color="secondary" class="ml-8" depressed @click="confirm">
          {{ $t('layout.components.appInstallPrompt.install') }}
        </v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleDialog() {
      this.$emit('input', !this.value);
    },
    cancel() {
      this.$emit('cancel');
      this.toggleDialog();
    },
    confirm() {
      this.$emit('confirm');
      this.toggleDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-install-prompt {
  margin-bottom: 5vw;

  :deep(ul) {
    padding-left: 20px;
    list-style-type: decimal;
  }
}
</style>
