<template>
  <v-btn
    color="primary"
    elevation="0"
    small
    fab
    :class="{ hidden: $vuetify.breakpoint.lgAndUp }"
    :ripple="false"
    @click="$emit('click')"
  >
    <inline-svg :src="require('@/modules/Layout/assets/icons/menu.svg')" width="23" />
  </v-btn>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg,
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  &:before {
    opacity: 0 !important;
  }
}
.hidden {
  visibility: hidden;
}
</style>
