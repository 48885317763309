<template>
  <div :style="{ '--mini-variant-width': `${miniVariantWidth}px`, '--width': `${width}px` }">
    <div :style="{ transform: `translate3d(${glider.positionX}px, ${glider.positionY}px, 0)` }" class="glider"></div>
    <v-navigation-drawer
      :mini-variant="miniVariant"
      :mini-variant-width="miniVariantWidth"
      :width="miniVariantWidth"
      hide-overlay
      permanent
      floating
      fixed
    >
      <drawer-content
        ref="drawer-content"
        :device-list="deviceList"
        :current-device-id="currentDeviceId"
        :menu-items="menuItems"
        :minimized="miniVariant"
        @deviceSwitch="onDeviceSwitch"
      />
      <template #append>
        <drawer-footer :footer-items="footerItems" :minimized="miniVariant" @logout="$emit('logout')" />
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useScreenSafeArea } from '@vueuse/core';
import DrawerContent from '@/modules/Layout/components/AuthLayout/Drawer/DrawerContent.vue';
import DrawerFooter from '@/modules/Layout/components/AuthLayout/Drawer/DrawerFooter.vue';
import { drawerMenuItems, drawerFooterItems } from '@/modules/Layout/constants';

const screenSafeArea = useScreenSafeArea();

export default {
  components: { DrawerContent, DrawerFooter },
  props: {
    deviceList: {
      type: Array,
      required: true,
    },
    currentDeviceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      miniVariant: true,
      glider: {
        positionList: [],
        positionX: -25,
        positionY: 250,
      },
    };
  },
  computed: {
    miniVariantWidth() {
      return 56 + parseInt(screenSafeArea.left.value);
    },
    width() {
      return 350 + parseInt(screenSafeArea.left.value);
    },
    menuItems() {
      return drawerMenuItems;
    },
    footerItems() {
      return drawerFooterItems;
    },
  },
  watch: {
    $route(to) {
      this.miniVariant = true;
      this.setGlider(to);
    },
  },
  mounted() {
    screenSafeArea.update();
    document.addEventListener('mousemove', this.onMouseMove);
    setTimeout(() => {
      this.setGliderPositionList();
      this.setGlider(this.$route);
    }, 100);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onMouseMove);
  },
  methods: {
    onDeviceSwitch(id) {
      this.$emit('deviceSwitch', id);
    },
    onMouseMove(event) {
      if (event.clientX < this.miniVariantWidth) {
        this.miniVariant = false;
      } else if (event.clientX > this.width) {
        this.miniVariant = true;
      }
    },
    setGliderPositionList() {
      this.glider.positionList = this.$refs?.['drawer-content']?.$refs?.['glider-position'].map(
        (ref) => ref.$el.getBoundingClientRect().top + ref.$el.getBoundingClientRect().height / 2
      );
    },
    setGlider(to) {
      const index = this.menuItems.findIndex((item) => to.name.startsWith(item.to.name));
      if (index > -1) {
        this.glider.positionY = this.glider.positionList[index];
        this.glider.positionX = 0;
      } else {
        this.glider.positionX = -25;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.glider {
  width: 25px;
  height: 150px;
  margin-top: -75px;
  background: url('@/modules/Layout/assets/glider-vertical.svg') no-repeat center center;
  background-size: contain;
  position: fixed;
  top: 0;
  left: var(--mini-variant-width);
  transition-duration: 0.5s;
  transition-property: transform;
}

.v-navigation-drawer {
  height: 100svh !important;
  padding-left: env(safe-area-inset-left);
  overflow: unset !important;
  background-color: var(--v-primary-base);
  box-shadow: none;
  transition-property: transform, visibility, width, background-color;

  ::v-deep(.v-navigation-drawer__content) {
    overflow: unset !important;
  }

  &:not(.v-navigation-drawer--mini-variant) {
    width: var(--width) !important;
    background-color: white;
    box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);

    ::v-deep(.v-navigation-drawer__content) {
      overflow-y: auto !important;
    }
  }
}
</style>
