export const layoutTranslations = {
  de: {
    layout: {
      components: {
        impersonateBar: {
          authorizedAs: 'Authentifiziert als',
        },
        footerComponent: {
          imprint: 'Impressum',
          privacy: 'Datenschutz',
          termsOfUse: 'AGB',
        },
        appInstallPrompt: {
          title: 'App installieren',
          text: 'Möchten Sie die App auf Ihrem Gerät installieren?',
          iosInstructions: {
            1: 'Auf "Teilen" tippen',
            2: '"Zum Home-Bildschirm" wählen',
            3: '"Hinzufügen" tippen',
            4: 'Jetzt kannst du es wie eine App nutzen',
          },
          install: 'Installieren',
          close: 'Schließen',
        },
      },
      constants: {
        drawerMenuItems: {
          dashboard: 'Übersicht',
          analysis: 'Analyse',
          generationAndConsumption: 'Erzeugung & Verbrauch',
          profitability: 'Wirtschaftlichkeit',
          ecology: 'Autarkie & Ökologie',
          car: 'sunbrain.car',
          settings: 'Einstellungen',
          contact: 'Kontakt',
        },
        drawerFooterItems: {
          imprint: 'Impressum',
          privacy: 'Datenschutz',
          termsOfUse: 'AGB',
        },
      },
    },
  },
};
