<template>
  <component :is="layout" v-if="ready" />
</template>

<script>
import AuthLayout from '@/modules/Layout/views/AuthLayout.vue';
import PublicLayout from '@/modules/Layout/views/PublicLayout.vue';

export default {
  components: {
    AuthLayout,
    PublicLayout,
  },
  computed: {
    ready() {
      return this.$route && this.$auth.ready();
    },
    layout() {
      if (true === this.$route?.meta?.usePublicLayout) {
        return 'PublicLayout';
      }
      return true === this.$route?.meta?.auth ? 'AuthLayout' : 'PublicLayout';
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        window.scrollTo(0, 0);
        document.title = to.meta.title ? `${this.$t(to.meta.title)} | Sunbrain` : 'Sunbrain';
      },
    },
  },
};
</script>

<style lang="css">
@import '@fontsource/poppins/300.css';
@import '@fontsource/poppins/400.css';
@import '@fontsource/poppins/500.css';
@import '@fontsource/poppins/600.css';
@import '@fontsource/poppins/700.css';
@import '@fortawesome/fontawesome-pro/css/all.css';
</style>

<style lang="scss">
.theme--light.v-application {
  color: var(--v-primary-base);
}
</style>
