<template>
  <v-system-bar height="30" color="red" class="pl-lg-12" app>
    <div class="mx-auto white--text">
      {{ $t('layout.components.impersonateBar.authorizedAs') }} {{ impersonateUser }}
    </div>
  </v-system-bar>
</template>

<script>
export default {
  computed: {
    impersonateUser() {
      return this.$auth.user().current.firstName + ' ' + this.$auth.user().current.lastName;
    },
  },
};
</script>
