<template>
  <v-btn v-if="$vuetify.breakpoint.xsOnly" :to="{ name: 'account' }" elevation="0" small fab :ripple="false">
    <inline-svg :src="require('@/modules/Layout/assets/icons/user.svg')" width="18" />
  </v-btn>
  <v-menu v-else content-class="account-menu elevation-0" offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        elevation="0"
        small
        fab
        :class="{ 'v-btn--active': $route.name.startsWith('account') }"
        :ripple="false"
        v-on="on"
      >
        <inline-svg :src="require('@/modules/Layout/assets/icons/user.svg')" width="18" />
      </v-btn>
    </template>
    <div class="triangle"></div>
    <div class="elevation-12 rounded-xl white pa-4">
      <slot />
    </div>
  </v-menu>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg,
  },
  props: {
    logMessageCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  color: var(--v-primary-base);
  background-color: var(--v-silver-base) !important;
  border: 1px solid var(--v-silverSecondary-base);
  transition-property: color, background-color;

  &:before {
    opacity: 0 !important;
  }

  &--active {
    color: white;
    background-color: var(--v-primary-base) !important;
    border: none !important;
  }
}
@media (hover: hover) {
  .v-btn {
    &:hover {
      color: white;
      background-color: var(--v-primary-base) !important;
      border: none !important;
    }

    &--active {
      &:hover {
        color: var(--v-primary-base);
        background-color: var(--v-silver-base) !important;
        border: none !important;
      }
    }
  }
}
.account-menu {
  overflow: visible;
  contain: none;

  left: auto !important;
  right: 10px !important;

  .triangle {
    content: '';
    width: 10px;
    height: 12px;
    margin: 4px 16px -2px auto;
    position: relative;
    z-index: 10;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid white;
  }
}
</style>
