export const CONTACT_NAMESPACE = 'CONTACT_NAMESPACE';

export const configureContactStore = (Vue) => {
  return {
    namespaced: true,
    state: {
      partnerConfiguration: null,
    },
    mutations: {
      setPartnerConfiguration(state, payload) {
        state.partnerConfiguration = payload;
      },
    },
    actions: {
      async fetchPartnerConfiguration({ commit }) {
        const response = await Vue.prototype.$http.get('/customer-ui/current-user/partner-configuration');
        commit('setPartnerConfiguration', response.data);
      },
    },
    getters: {
      mainPartner: (state) =>
        state.partnerConfiguration?.partnerCollection?.[state.partnerConfiguration.mainPartnerKey] ?? null,
      visiblePartners: (state) =>
        Object.entries(state.partnerConfiguration?.partnerCollection ?? {})
          .map(([key, value]) => ({ ...value, key }))
          .filter((partner) => partner.isVisible)
          .sort((a, b) => ('default' === a.key ? -1 : a.key.localeCompare(b.key))),
    },
  };
};
