const LOCALSTORAGE_IOS_INSTALL_PROMPT_SHOWN_KEY = 'iosInstallModalShown';

export const showIosInstallPrompt = () => {
  // detect if the device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // check if the device is in standalone mode
  const isInStandaloneMode = () => {
    return 'standalone' in window.navigator && window.navigator.standalone;
  };

  // show the modal only once
  const localStorageKeyValue = localStorage.getItem(LOCALSTORAGE_IOS_INSTALL_PROMPT_SHOWN_KEY);
  const iosInstallModalShown = localStorageKeyValue ? JSON.parse(localStorageKeyValue) : false;

  if (isIos() && !isInStandaloneMode() && !iosInstallModalShown) {
    localStorage.setItem(LOCALSTORAGE_IOS_INSTALL_PROMPT_SHOWN_KEY, 'true');
    return true;
  }

  return false;
};
