export const contactRoutes = [
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/modules/Contact/views/ContactView.vue'),
    meta: {
      title: 'contact.views.contactView.title',
      headline: 'contact.headline',
      auth: true,
    },
  },
];
