export const settingsRoutes = [
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/modules/Settings/views/SettingsView.vue'),
    meta: {
      title: 'settings.views.settingsView.title',
      headline: 'settings.headline',
      auth: true,
    },
  },
  {
    path: '/settings/relais',
    name: 'settings-relais',
    component: () => import(/* webpackChunkName: "settings-relais" */ '@/modules/Settings/views/RelaisSettings.vue'),
    meta: {
      title: 'settings.views.relaisSettings.title',
      headline: 'settings.headline',
      auth: true,
    },
  },
];
