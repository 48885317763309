export const analysisTranslations = {
  de: {
    analysis: {
      headline: 'Analyse',
      views: {
        analysisView: {
          title: 'Analyse',
          today: 'Heute',
          generation: 'Erzeugung',
          consumption: 'Verbrauch',
          saved: 'gespart',
          valueOf: 'im Wert von',
          co2Saving: 'CO² Ersparnis',
          more: 'Mehr',
        },
        generationAndConsumption: {
          title: 'Erzeugung & Verbrauch',
          generationAndConsumption: 'Erzeugung & Verbrauch',
          generators: 'Erzeuger',
          showGenerators: 'Erzeuger anzeigen',
          consumers: 'Verbraucher',
          showConsumers: 'Verbraucher anzeigen',
          energyBalance: 'Energiebilanz',
          splitChart: 'Chart teilen',
          combineCharts: 'Charts zusammenführen',
        },
        profitabilityView: {
          title: 'Wirtschaftlichkeit',
          subtitle:
            '<strong>Sonnenstrom lohnt sich – Erzeugte Energie</strong> von Photovoltaik (PV) als Wert nach Zeitperspektive.',
          subtitleLg:
            'Um die Wirtschaftlichkeit zu bewerten, ist es wichtig, die Einsparungen zu betrachten. Im Fall von Solarpanelen entfällt der Bedarf an fossilen Brennstoffen wie Benzin und Diesel, was zu erheblichen Kosteneinsparungen führen kann.',
          generated: 'Erzeugt',
          saving: 'Ersparnis',
          euro: 'Euro',
          savingInUnit: 'Euro pro',
          hint: '*Zugrunde gelegt wurden dabei die aktuellen Preise vom April 2023.',
        },
        ecologyView: {
          title: 'Autarkie & Ökologie',
          titleWithBreak: 'Autarkie &<br> Ökologie',
          subtitle:
            '<strong>Autarkiegrad bedeutet, wie viel Energiebedarf ein Haushalt</strong> unabhängig von der Stromversorgung durch das öffentliche Netz produziert.',
          description:
            'Hier kannst du einen Eigenverbrauch von 80 bis fast 100 Prozent realisieren. Eine autarke Stromversorgung nur mit einer Solarstromanlage ist auch machbar.',
          selfSufficiency: 'autark',
          generation: 'Erzeugung',
          powerSupply: 'Netzbezug',
          feed: 'Einspeisung',
          feedSubtitle: 'Einspeisung ins Netz',
          feedDescription: 'Sie speisen einen Teil Ihres Stroms ins <strong>öffentliche Netz</strong> ein.',
          co2BalanceTitle: 'Mit dem Strom,',
          co2BalanceSubtitle: 'den Ihre Sunbrain PW-Anlage dieses Jahr bereits erzeugt hat, konnten sich',
          co2BalanceDescription: 'CO₂-Emission gegenüber Kohlestrom einsparen lassen.',
          footprintTitle: 'Zum Vergleich:',
          footprintSubtitle: 'Das entspricht',
          footprintDescription: 'deines jährlichen Fußabdrucks.',
          treesTitle: 'Das entspricht',
          treesSubtitle: 'in etwa dem CO₂ welches in einem Jahr von',
          treesDescription: 'Bäumen gebunden werden kann.',
        },
      },
      components: {
        scalingForm: {
          day: 'Tag',
          month: 'Monat',
          year: 'Jahr',
          today: 'Heute',
          yesterday: 'Gestern',
        },
        timespanChart: {
          TOTAL_GENERATION: 'Erzeugung',
          TOTAL_CONSUMPTION: 'Verbrauch',
          TOTAL_DIFFERENCE: 'Differenz',
          BATTERY: 'Batterie',
          INVERTER1: 'Wechselrichter 1',
          INVERTER2: 'Wechselrichter 2',
          INVERTER3: 'Wechselrichter 3',
        },
        pieChartGroup: {
          generation: 'Erzeugung',
          consumption: 'Verbrauch',
          selfUse: 'Selbst genutzt',
          feedIn: 'Einspeisung',
          selfGenerate: 'Erzeugter Strom',
          powerSupply: 'Netzbezug',
        },
        legendComponent: {
          selectAll: 'Alle anwählen',
        },
      },
    },
  },
};
