<template>
  <v-app>
    <app-install-prompt
      :value="showInstallPrompt"
      :show-confirm-button="true"
      @cancel="cancelPrompt"
      @confirm="confirmPrompt"
    >
      {{ $t('layout.components.appInstallPrompt.text') }}
    </app-install-prompt>
    <app-install-prompt :value="!showInstallPrompt && showIosPrompt" @cancel="cancelPrompt">
      {{ $t('layout.components.appInstallPrompt.text') }}
      <div class="pt-3">
        <ul>
          <li>
            <div class="d-flex">
              {{ $t('layout.components.appInstallPrompt.iosInstructions.1') }}
              <inline-svg :src="require('@/modules/Layout/assets/icons/share-ios.svg')" width="18" class="ml-1" />
            </div>
          </li>
          <li>{{ $t('layout.components.appInstallPrompt.iosInstructions.2') }}</li>
          <li>{{ $t('layout.components.appInstallPrompt.iosInstructions.3') }}</li>
          <li>{{ $t('layout.components.appInstallPrompt.iosInstructions.4') }}</li>
        </ul>
      </div>
    </app-install-prompt>
    <slot />
  </v-app>
</template>

<script>
import AppInstallPrompt from '@/modules/Layout/components/AppInstallPrompt.vue';
import InlineSvg from 'vue-inline-svg';
import { showIosInstallPrompt } from '@/modules/Layout/helper/pwa';

export default {
  components: {
    AppInstallPrompt,
    InlineSvg,
  },
  data() {
    return {
      showInstallPrompt: false,
      showIosPrompt: false,
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener('beforeinstallprompt', this.beforeInstallPrompt);
    window.addEventListener('appinstalled', this.appInstalled);
    this.showIosPrompt = showIosInstallPrompt();
  },
  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt', this.beforeInstallPrompt);
    window.removeEventListener('appinstalled', this.appInstalled);
  },
  methods: {
    beforeInstallPrompt(event) {
      event.preventDefault();
      this.showInstallPrompt = true;
      this.deferredPrompt = event;
    },
    appInstalled() {
      this.showInstallPrompt = false;
      this.deferredPrompt = null;
    },
    cancelPrompt() {
      this.showInstallPrompt = false;
      this.showIosPrompt = false;
      this.deferredPrompt = null;
    },
    confirmPrompt() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
      }
    },
  },
};
</script>
