export const analysisRoutes = [
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import(/* webpackChunkName: "analysis" */ '@/modules/Analysis/views/AnalysisView.vue'),
    meta: {
      title: 'analysis.views.analysisView.title',
      headline: 'analysis.headline',
      auth: true,
    },
  },
  {
    path: '/analysis/generation-and-consumption',
    name: 'analysis-generation-and-consumption',
    component: () =>
      import(
        /* webpackChunkName: "analysis-generation-and-consumption" */ '@/modules/Analysis/views/GenerationAndConsumption.vue'
      ),
    meta: {
      title: 'analysis.views.generationAndConsumption.title',
      headline: 'analysis.headline',
      auth: true,
      showBackButton: true,
    },
  },
  {
    path: '/analysis/profitability',
    name: 'analysis-profitability',
    component: () =>
      import(/* webpackChunkName: "analysis-profitability" */ '@/modules/Analysis/views/ProfitabilityView.vue'),
    meta: {
      title: 'analysis.views.profitabilityView.title',
      headline: 'analysis.headline',
      auth: true,
      showBackButton: true,
    },
  },
  {
    path: '/analysis/ecology',
    name: 'analysis-ecology',
    component: () => import(/* webpackChunkName: "analysis-ecology" */ '@/modules/Analysis/views/EcologyView.vue'),
    meta: {
      title: 'analysis.views.ecologyView.title',
      headline: 'analysis.headline',
      auth: true,
      showBackButton: true,
    },
  },
];
