export const legalTranslations = {
  de: {
    legal: {
      views: {
        imprintView: {
          title: 'Impressum',
        },
        privacyView: {
          title: 'Datenschutz',
        },
      },
    },
  },
};
