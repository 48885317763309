export const carRoutes = [
  {
    path: '/car',
    name: 'car',
    component: () => import(/* webpackChunkName: "car" */ '@/modules/Car/views/CarView.vue'),
    meta: {
      title: 'car.views.carView.title',
      headline: 'car.headline',
      auth: true,
    },
  },
  {
    path: '/car/advanced-mode/:program([256])',
    name: 'car-advanced-mode',
    component: () => import(/* webpackChunkName: "car-advanced-mode" */ '@/modules/Car/views/AdvancedMode.vue'),
    meta: {
      title: 'car.views.advancedMode.title',
      headline: 'car.headline',
      auth: true,
    },
  },
];
