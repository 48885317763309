export const legalRoutes = [
  {
    path: '/imprint',
    name: 'imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '@/modules/Legal/views/ImprintView.vue'),
    meta: {
      title: 'legal.views.imprintView.title',
      useSingleColumnOnly: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '@/modules/Legal/views/PrivacyView.vue'),
    meta: {
      title: 'legal.views.privacyView.title',
      useSingleColumnOnly: true,
    },
  },
];
