<template>
  <base-layout>
    <v-main>
      <v-container class="fill-height">
        <v-row class="fill-height mx-n3" no-gutters>
          <v-col v-bind="leftColumnAttrs" class="px-6 px-md-0 py-12">
            <router-view></router-view>
          </v-col>
          <v-col v-bind="rightColumnAttrs">
            <v-img v-bind="houseAttrs" max-width="100vw" contain />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <footer-component />
  </base-layout>
</template>

<script>
import BaseLayout from '@/modules/Layout/views/BaseLayout.vue';
import { useScreenOrientation } from '@vueuse/core';
import FooterComponent from '@/modules/Layout/components/PublicLayout/FooterComponent.vue';

const screenOrientation = useScreenOrientation();

export default {
  components: {
    BaseLayout,
    FooterComponent,
  },
  computed: {
    useSingleColumn() {
      return (
        'portrait-primary' === screenOrientation.orientation.value ||
        true === this.$route?.meta?.useSingleColumnOnly ||
        this.$vuetify.breakpoint.smAndDown
      );
    },
    leftColumnAttrs() {
      return this.useSingleColumn ? { cols: 12, class: 'align-self-start' } : { cols: 6, class: 'align-self-center' };
    },
    rightColumnAttrs() {
      return this.useSingleColumn ? { cols: 12, class: 'align-self-end' } : { cols: 6, class: 'align-self-end' };
    },
    houseAttrs() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return {
            src: this.useSingleColumn
              ? require('@/modules/Layout/assets/house.svg')
              : require('@/modules/Layout/assets/house-big.svg'),
            position: this.useSingleColumn ? 'bottom center' : 'bottom left',
            height: this.useSingleColumn ? 400 : 900,
            style: this.useSingleColumn ? { marginBottom: '-70px' } : { marginBottom: '-110px' },
          };
        case 'lg':
          return {
            src: this.useSingleColumn
              ? require('@/modules/Layout/assets/house.svg')
              : require('@/modules/Layout/assets/house-big.svg'),
            position: this.useSingleColumn ? 'bottom center' : 'bottom right',
            height: this.useSingleColumn ? 400 : 800,
            style: this.useSingleColumn ? { marginBottom: '-70px' } : { marginBottom: '-95px' },
          };
        case 'md':
          return {
            src: this.useSingleColumn
              ? require('@/modules/Layout/assets/house.svg')
              : require('@/modules/Layout/assets/house-big.svg'),
            position: this.useSingleColumn ? 'bottom center' : 'bottom right',
            height: 500,
            style: { marginBottom: '-80px' },
          };
        case 'sm':
          return {
            src: require('@/modules/Layout/assets/house.svg'),
            position: 'bottom center',
            height: 400,
            style: { marginBottom: '-70px' },
          };
        default:
          return {
            src: require('@/modules/Layout/assets/house.svg'),
            position: 'bottom center',
            height: 300,
            style: { marginBottom: '-55px' },
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.v-application--wrap) {
  min-height: 100svh;
}
::v-deep(.v-main__wrap) {
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
.v-image {
  z-index: 1;
}
</style>
