<template>
  <div :class="{ minimized }" class="drawer-footer">
    <template v-if="showFullFooter">
      <v-img :src="require('@/modules/Layout/assets/house.svg')" max-width="450" class="house mx-auto" />
      <v-footer color="primary" height="200">
        <v-row>
          <v-col cols="12">
            <v-img :src="require('@/modules/Layout/assets/logo-dark.svg')" width="190" class="mx-auto" contain />
          </v-col>
          <v-col cols="12" class="text-center">
            <template v-for="({ to, exact, title }, key) in footerItems">
              <router-link :key="key" :to="to" :exact="exact" class="mx-2">{{ $t(title) }}</router-link>
            </template>
          </v-col>
        </v-row>
      </v-footer>
    </template>
    <div v-else class="text-center pb-12">
      <template v-for="({ to, exact, title }, key) in footerItems">
        <router-link v-slot="{ href }" :key="key" :to="to" :exact="exact">
          <a :href="href" target="_blank" class="d-inline-block mx-2">{{ $t(title) }}</a>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { useScreenOrientation } from '@vueuse/core';

const screenOrientation = useScreenOrientation();

export default {
  props: {
    footerItems: {
      type: Array,
      required: true,
    },
    minimized: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showFullFooter() {
      return this.$vuetify.breakpoint.mdAndUp && screenOrientation.orientation.value === 'portrait-primary';
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-footer {
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;

  a {
    color: var(--v-grey-base);
    text-decoration: none;

    &:hover {
      color: var(--v-secondary-base);
    }
  }

  .house {
    margin-bottom: -55px;
    z-index: 10;
  }

  .v-footer {
    border-top-left-radius: 75px;

    a {
      color: white;
      text-decoration: none;
      display: inline-block;

      &:hover {
        color: var(--v-secondary-base);
      }
    }
  }

  &:not(.minimized) {
    opacity: 1;
  }
}
</style>
