<template>
  <base-layout v-if="isDeviceSelected && $auth.check()" :style="style">
    <impersonate-bar v-if="isImpersonating" />
    <header-component :title="$route.meta.headline || ''">
      <template #left>
        <back-button v-if="$route.meta.showBackButton" @click="$router.push({ name: 'analysis' })" />
        <hamburger-menu v-else @click="drawer = true" />
      </template>
      <template #right>
        <account-menu>
          <account-navigation :log-message-count="logMessageCount" @logout="onLogout" />
        </account-menu>
      </template>
    </header-component>
    <desktop-drawer
      v-if="$vuetify.breakpoint.lgAndUp"
      :device-list="deviceList"
      :current-device-id="currentDeviceId"
      @deviceSwitch="onDeviceSwitch"
    />
    <template v-else>
      <mobile-drawer
        v-model="drawer"
        :device-list="deviceList"
        :current-device-id="currentDeviceId"
        @deviceSwitch="onDeviceSwitch"
      />
      <bottom-navigation />
    </template>
    <v-main>
      <router-view></router-view>
    </v-main>
  </base-layout>
</template>

<script>
import BaseLayout from '@/modules/Layout/views/BaseLayout.vue';
import HeaderComponent from '@/modules/Layout/components/AuthLayout/HeaderComponent.vue';
import HamburgerMenu from '@/modules/Layout/components/AuthLayout/HamburgerMenu.vue';
import AccountMenu from '@/modules/Layout/components/AuthLayout/AccountMenu.vue';
import AccountNavigation from '@/modules/Account/components/AccountNavigation.vue';
import DesktopDrawer from '@/modules/Layout/components/AuthLayout/DesktopDrawer.vue';
import MobileDrawer from '@/modules/Layout/components/AuthLayout/MobileDrawer.vue';
import BottomNavigation from '@/modules/Layout/components/AuthLayout/BottomNavigation.vue';
import BackButton from '@/modules/Layout/components/AuthLayout/BackButton.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/modules/Layout/stores';
import { CONTACT_NAMESPACE } from '@/modules/Contact/stores';
import ImpersonateBar from '@/modules/Layout/components/AuthLayout/ImpersonateBar.vue';

export default {
  components: {
    BaseLayout,
    ImpersonateBar,
    HeaderComponent,
    HamburgerMenu,
    AccountMenu,
    AccountNavigation,
    DesktopDrawer,
    MobileDrawer,
    BottomNavigation,
    BackButton,
  },
  data() {
    return {
      drawer: false,
      interval: null,
    };
  },
  computed: {
    ...mapState(LAYOUT_NAMESPACE, ['logMessageCount', 'deviceList', 'currentDeviceId']),
    style() {
      return {
        '--impersonate-bar-height': this.isImpersonating ? '30px' : '0',
      };
    },
    isImpersonating() {
      return this.$auth.user().isImpersonating;
    },
    isDeviceSelected() {
      return null !== this.currentDeviceId;
    },
  },
  watch: {
    currentDeviceId() {
      this.fetchLogMessagesCount();
    },
  },
  created() {
    if (this.isDeviceSelected) {
      this.fetchLogMessagesCount();
      this.interval = setInterval(this.fetchLogMessagesCount, 60000);
    }
  },
  async mounted() {
    if (false === this.$auth.user().current.roles.includes('ROLE_VIEWER')) {
      await this.$auth.logout();
      return;
    }
    await this.fetchDeviceList();
    await this.fetchPartnerConfiguration();
    if (1 === this.deviceList.length) {
      this.setCurrentDeviceId(this.deviceList[0].id);
    }
    if (false === this.isDeviceSelected) {
      await this.$router.push({ name: 'device-selection' });
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapMutations(LAYOUT_NAMESPACE, ['setCurrentDeviceId']),
    ...mapActions(LAYOUT_NAMESPACE, ['fetchDeviceList', 'fetchLogMessagesCount']),
    ...mapActions(CONTACT_NAMESPACE, ['fetchPartnerConfiguration']),
    onDeviceSwitch(id) {
      this.setCurrentDeviceId(id);
    },
    onLogout() {
      this.$auth.logout({
        redirect: { name: 'login' },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar {
  top: var(--impersonate-bar-height);
}
::v-deep(.v-application--wrap) {
  min-height: 100svh;
}
::v-deep(.v-main__wrap) {
  padding: calc(56px + env(safe-area-inset-top)) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .v-application {
    background: url('@/modules/Layout/assets/background-top-left.svg') no-repeat top left fixed,
      url('@/modules/Layout/assets/background-bottom-left.svg') no-repeat bottom left fixed,
      url('@/modules/Layout/assets/background-bottom-right.svg') no-repeat bottom right fixed;
    background-size: 60vw 40vh, 40vw 40vh, 50vw 40vh;
  }
}
@media #{map-get($display-breakpoints, 'lg-and-up')} {
  ::v-deep(.v-main__wrap) {
    padding-left: calc(56px + env(safe-area-inset-left));
  }
}
@media #{map-get($display-breakpoints, 'md-and-down')} {
  ::v-deep(.v-main__wrap) {
    padding-bottom: calc(56px + env(safe-area-inset-bottom));
  }
}
</style>
