import { LAYOUT_NAMESPACE } from '@/modules/Layout/stores';

export const SETTINGS_NAMESPACE = 'SETTINGS_NAMESPACE';

export const configureSettingsStore = (Vue) => {
  return {
    namespaced: true,
    state: {
      metaData: {
        serial: null,
        softwareVersion: null,
        hardwareVersion: null,
        lastActivity: null,
      },
      offline: false,
      originFormData: {
        defaultSettings: {},
        advancedSettings: {},
      },
      formData: {
        defaultSettings: {},
        advancedSettings: {},
      },
    },
    mutations: {
      setMetaData(state, payload) {
        state.metaData = payload;
      },
      setOffline(state, payload) {
        state.offline = payload;
      },
      setOriginFormData(state, payload) {
        state.originFormData = payload;
      },
      setFormData(state, payload) {
        state.formData = payload;
      },
    },
    actions: {
      async fetchMetaData({ rootState, commit }) {
        const response = await Vue.prototype.$http.get('/customer-ui/sunbrain/data', {
          params: {
            sunbrainId: rootState[LAYOUT_NAMESPACE].currentDeviceId,
          },
        });
        commit('setMetaData', {
          serial: response.data.serial || null,
          softwareVersion: response.data.softwareVersion || null,
          hardwareVersion: response.data.hardwareVersion || null,
          lastActivity: response.data.lastActivity || null,
        });
      },
      async fetchSettings({ rootState, commit }) {
        const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
        const defaultSettingsResponse = await Vue.prototype.$http.get(
          '/device-management/sunbrain/additional-settings',
          {
            params: {
              sunbrainId,
            },
          }
        );
        const advancedSettingsResponse = await Vue.prototype.$http.get('/device-management/sunbrain/settings/current', {
          params: {
            sunbrainId,
          },
        });
        commit('setOffline', advancedSettingsResponse.data.offline);
        commit('setOriginFormData', {
          defaultSettings: defaultSettingsResponse.data,
          advancedSettings: advancedSettingsResponse.data.data,
        });
        commit('setFormData', {
          defaultSettings: defaultSettingsResponse.data,
          advancedSettings: advancedSettingsResponse.data.data,
        });
      },
      async updateDefaultSettings({ rootState, state }) {
        try {
          const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
          await Vue.prototype.$http.post('/device-management/sunbrain/update-additional-settings', {
            ...state.formData.defaultSettings,
            sunbrainId,
          });
        } catch (error) {
          return error.response?.data?.code || -1;
        }
      },
      async updateAdvancedSetting({ rootState }, { key, value }) {
        try {
          const sunbrainId = rootState[LAYOUT_NAMESPACE].currentDeviceId;
          await Vue.prototype.$http.post('/device-management/sunbrain/settings/update', {
            sunbrainId,
            key,
            value,
          });
        } catch (error) {
          return error.response?.data?.code || -1;
        }
      },
    },
    getters: {},
  };
};
