<template>
  <v-list class="transparent my-n3 py-0">
    <template v-for="({ to, exact, badge, icon, title, show }, key) in menuItems">
      <v-list-item v-if="show" :key="key" :to="to" :exact="exact" :ripple="false" class="rounded-lg my-3">
        <v-list-item-icon>
          <v-badge v-if="badge" :content="logMessageCount.toString()" offset-y="17" offset-x="16" bordered>
            <inline-svg :src="icon" />
          </v-badge>
          <template v-else>
            <inline-svg :src="icon" />
          </template>
        </v-list-item-icon>
        <v-list-item-title>{{ $t(title) }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>fa-light fa-arrow-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
    <v-list-item :ripple="false" class="rounded-lg my-3" @click="$emit('logout')">
      <v-list-item-title>
        <div class="text-center">Logout</div>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  components: {
    InlineSvg,
  },
  props: {
    logMessageCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuItems: [
        {
          icon: require('@/modules/Layout/assets/icons/user-dark.svg'),
          to: { name: 'account-contact-data' },
          title: 'account.components.accountNavigation.contactData',
          exact: true,
          show: true !== this.$auth.user().isImpersonating,
        },
        {
          icon: require('@/modules/Layout/assets/icons/lock.svg'),
          to: { name: 'account-change-password' },
          title: 'account.components.accountNavigation.changePassword',
          exact: true,
          show: true !== this.$auth.user().isImpersonating,
        },
        {
          icon: require('@/modules/Layout/assets/icons/bell.svg'),
          to: { name: 'account-log-messages' },
          title: 'account.components.accountNavigation.logMessages',
          exact: true,
          badge: true,
          show: true,
        },
        {
          icon: require('@/modules/Layout/assets/icons/mail.svg'),
          to: { name: 'account-daily-report-mail' },
          title: 'account.components.accountNavigation.dailyReportMail',
          exact: true,
          show: true !== this.$auth.user().isImpersonating,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  min-height: 50px;
  background-color: #f8f8f8;
  transition-property: background-color, color;
  transition-duration: 0.2s;

  .v-list-item__title:last-of-type {
    color: var(--v-red-base);
  }

  .v-list-item__icon {
    margin: 0 24px 0 0;
    align-self: center;
    color: var(--v-secondary-base);

    &:last-of-type {
      margin: 0 0 0 24px;
    }

    .v-badge {
      ::v-deep(.v-badge__badge) {
        color: var(--v-primary-base);
        background-color: transparent !important;

        &::after {
          border: 1px solid var(--v-secondary-base);
          transform: scale(0.8);
        }
      }
    }

    i.v-icon {
      color: var(--v-secondary-base);
    }
  }

  &::before {
    opacity: 0 !important;
  }

  &:hover {
    .v-list-item__title {
      color: var(--v-secondary-base);

      &:last-of-type {
        color: var(--v-redSecondary-base);
      }
    }
    .v-list-item__icon:last-child {
      i.v-icon {
        transform: scale(1.2);
      }
    }
  }

  &--active {
    background-color: var(--v-accent-base) !important;

    .v-list-item__icon,
    .v-list-item__title {
      color: white !important;
    }

    .v-list-item__icon {
      .v-badge {
        ::v-deep(.v-badge__badge) {
          &::after {
            border: 1px solid white;
          }
        }
      }

      i.v-icon {
        color: white;
      }
    }
  }
}
</style>
