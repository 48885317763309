<template>
  <v-footer color="primary" height="200">
    <v-container class="mt-sm-9 mt-md-0">
      <v-row align="end">
        <v-col cols="12" md="" class="text-center text-md-left">
          <v-img :src="require('@/modules/Layout/assets/logo-dark.svg')" :width="logoWidth" contain />
        </v-col>
        <v-col cols="12" md="auto" class="text-center text-md-right">
          <template v-for="({ to, title }, key) in menuItems">
            <router-link v-slot="{ href }" :key="key" :to="to">
              <a :href="href" target="_blank" class="mx-2">{{ $t(title) }}</a>
            </router-link>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { to: { name: 'imprint' }, title: 'layout.components.footerComponent.imprint' },
        { to: { name: 'privacy' }, title: 'layout.components.footerComponent.privacy' },
      ],
    };
  },
  computed: {
    logoWidth() {
      return this.$vuetify.breakpoint.smAndUp ? 190 : 120;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-footer {
  border-top-left-radius: 75px;

  a {
    color: white;
    text-decoration: none;
    display: inline-block;

    &:hover {
      color: var(--v-accent-base);
    }
  }
}
</style>
